/* start growing */

.start-section {
  text-align: center;
  color: #ffffff;
  padding: 125px 25px 55vw;
  background-image: url(../images/start-growing-bg-4.svg),
    url(../images/start-growing-bg-2.svg), url(../images/start-growing-bg-1.svg);
  background-position: bottom -5vw center, bottom center, top center;
  background-size: 160vw auto, 100vw auto, cover;
  background-repeat: no-repeat;
}

@media (min-width: 1440px) {
  .start-section {
    padding: 125px 25px 792px;
    background-position: bottom calc((1440px - 100vw) * 0.25 - 72px) center,
      bottom calc((1440px - 100vw) * 0.5) center, top center;
    background-size: 160vw auto, 100vw auto, cover;
  }
}

.start-section h2 {
  margin: 0 auto;
  max-width: 900px;
  font-size: 60px;
  line-height: 73px;
  margin-bottom: 24px;
  font-weight: 700;
}

.start-section p {
  margin: 0 auto;
  max-width: 900px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 36px;
}

.start-section .call-action {
  display: inline-block;
  margin: 0 auto 40px;
  width: auto;
  min-width: 230px;
  padding: 19px;
  font-size: 18px;
  line-height: 22px;
  background-color: #f9ae3b;
  border: 1px solid #f9ae3b;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 700;
  border: 0;
}
.wave-mobile {
  display: none;
}

@media (max-width: 767px) {
  .start-section {
    padding: max(18.25vw, 100px) 25px 55vw;
    background-image: url(../images/wave-mobile.png),
      url(../images/start-growing-bg-4.svg),
      url(../images/start-growing-bg-2.svg),
      url(../images/start-growing-bg-1.svg);
    background-size: 100% max(18.25vw, 100px), 160vw auto, 100vw auto, cover;
    background-position: top 0 center, bottom -5vw center, bottom -10px center,
      top center;
  }

  .start-section__phone-bg {
    display: none;
  }

  .start-section h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: left;
    margin-bottom: 16px;
  }

  .start-section p {
    margin-bottom: 36px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
  }

  .start-section__button,
  .start-section .call-action {
    margin: 0 auto 34px;
    padding: 14px 18px;
    font-size: 16px;
    line-height: 20px;
    min-width: auto;
    width: 100%;
    max-width: 288px;
  }
}


@media (max-width: 359px) {
  .start-section h2 {
    font-size: 32px;
    line-height: 39px;
  }
}
